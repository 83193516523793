import firebase from 'firebase/app'
import "firebase/auth";
import "firebase/firestore";


const firebaseConfig = {
  apiKey: "AIzaSyDkx34WNBRgvTaTv3ayAAUx-jeR_UFPvl4",
  authDomain: "t-app-f54f2.firebaseapp.com",
  databaseURL: "https://t-app-f54f2.firebaseio.com",
  projectId: "t-app-f54f2",
  storageBucket: "t-app-f54f2.appspot.com",
  messagingSenderId: "613315841700",
  appId: "1:613315841700:web:09d87fc37420fe19e4e031"
};
// Initialize Firebase

firebase.initializeApp(firebaseConfig);

const db = firebase.firestore()
const auth = firebase.auth()
const currentUser = auth.currentUser

const usersCollection = db.collection('users')

export {
  db,
  auth,
  currentUser,
  usersCollection
}
<template>
  <div class="modal">
    <div class="header">
      <div>Buchung am <span class="bold">{{booking.date}}, {{booking.time}} - {{booking.endTime}} Uhr</span></div>
      <div @click="abbrechen" class="pointer"><span class="material-icons icon bold" style="color: #f0f0f0">close</span></div>
    </div>

    <div class="content">
      <div style="margin-bottom: 20px">
        <div class="uberschrift light"> <span class="bold">Behandlung: </span>{{booking.type}} mit {{booking.calendar}}</div>
        <div class="uberschrift light" v-if="booking.forms[1]"><span class="bold" v-if="booking.forms[1].values[0].value">Anmerkungen: </span>{{booking.forms[1].values[0].value}}</div>
      </div>
      <div class="buttons">
         <div v-if="booking.canClientReschedule && !cancel" :class="{ closeEdit : edit }">
          <button v-if="!edit" class="btn bearbeiten" @click="openEdit">Verschieben</button>
        </div>
        <div v-if="booking.canClientCancel && !edit" :class="{ closeEdit : cancel}">
          <button v-if="!cancel" class="btn bearbeiten warn" @click="openCancel">Stornieren</button>
        </div>
      </div>
     
      <div v-if="edit">
        <div>
          
        </div>
        <div class="selects-container">
          <div class="uberschrift" style="margin-right: 10px">Neue Uhrzeit</div>
          <div>
            <div v-if="loadingTimes" class="loading">
              <Spinner line-fg-color="#2c3e50" line-bg-color="#fff" />
            </div>
            <select v-else-if="newAv.length" v-model="selectedTime" class="sel sel-small">
              <option v-for="av in newAv">{{ av }}</option>
            </select>
            <div v-else>Leider keine Termine verfügbar</div>
          </div>
        </div>

        <div v-if="selectedTime">
          <div class="uberschrift light" style="margin: 20px 0px">Verschieben auf {{booking.date}}, <span class="bold">{{selectedTime}} </span>Uhr?</div>
          <div class="buttons">
            <button @click="rescheduleAppointment" class="btn bearbeiten">Ok</button>
            <button @click="edit = false" class="btn bearbeiten btnLight">Abbrechen</button>
          </div>
        </div>
      </div>

      <div v-if="cancel">
        <div class="uberschrift">Buchung stornieren?</div>
        <div class="buttons">
          <button class="btn bearbeiten warn" @click="cancelAppointment">Ok</button>
          <button class="btn bearbeiten btnLight" @click="cancel = false">Abbrechen</button>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import Spinner from "vue-simple-spinner";

export default {
  props: ["appTypes"],
  components: {
    Spinner
  },
  data() {
    return {
      edit: false,
      cancel: false,
      loadingTimes: false,
      newAv: [],
      selectedTime: ""
    };
  },
  methods: {
    openEdit() {
      this.edit = !this.edit;
      this.fetchAvTimes();
    },
    openCancel() {
      this.cancel = !this.cancel;
    },
    abbrechen() {
      this.$router.push({ name: "dashboard" });
    },
    get10MinSlots(day, startTime, endTime) {
      const start = moment(day + startTime, 'YYYY-MM-DDHH:mm');
      const end = moment(day + endTime, 'YYYY-MM-DDHH:mm');
      const timeSeries = [];

      while (start.isSameOrBefore(end)) {
        timeSeries.push(start.add(10, 'm').format('YYYY-MM-DDTHH:mm:ssZZ'));
      }
      return timeSeries
    },
    getBlocksForWeek(calId, min, max) {
      return this.$store.dispatch("fetchBlocksForCal", { id: calId, min: min, max: max})
    },
    getBookingsForWeek(calId, min, max) {
      return this.$store.dispatch("fetchApsForCal", { id: calId, min: min, max: max})
    },
    async fetchAvTimes() {
      this.loadingTimes = true
      // get day of booking
      let day = moment(this.booking.datetime).format('YYYY-MM-DD')

      //get appointment type from current booking
      let appT = this.appointmentTypes.filter(el => {
        return el.id == this.booking.appointmentTypeID
      })
      appT = appT[0]

      let slots

      // get day slots
      if ( day === moment().format('YYYY-MM-DD')) {
        let start = moment().add(2, 'hours')
        let remainder = 10 - (start.minute() % 10)
        const startTime = moment(start)
          .add(remainder, "minutes")
          .format("HH:mm")
        slots = this.get10MinSlots(day, startTime, '22:00:00')
      } else {
        slots = this.get10MinSlots(day, '06:00', '22:00:00')
      }

      // get blocks for day
      await this.getBlocksForWeek(this.booking.calendarID, day, day)
      let blocks = JSON.parse(JSON.stringify(this.$store.state.calBlocks))

      // augment blocks
      blocks.forEach(el => {
        el.start = moment(el.start, 'YYYY-MM-DDTHH:mm:ssZZ').subtract(appT.duration + 10, 'minutes').format('YYYY-MM-DDTHH:mm:ssZZ')
      })

      // get bookings for day
      await this.getBookingsForWeek(this.booking.calendarID, day, day)
      let bookings = JSON.parse(JSON.stringify(this.$store.state.calAps))

      // augment bookings
      if (bookings.length) {
        bookings.forEach(booking => {
          if (booking.email === this.userProfile.email) {
            // console.log('at hotel', booking)
            booking.start = moment(booking.time, "HH:mm").subtract(appT.duration + 10, 'minutes').format("HH:mm")
            booking.end = moment(booking.endTime, "HH:mm").add(10, 'minutes').format("HH:mm")
          } else if (booking.category === "API Test GiT") {
            // console.log('at GIT', booking)
            booking.start = moment(booking.time, "HH:mm").subtract(appT.duration + 90, 'minutes').format("HH:mm")
            booking.end = moment(booking.endTime, "HH:mm").add(90, 'minutes').format("HH:mm")
          } else {
            // console.log('not at hotel', booking)
            booking.start = moment(booking.time, "HH:mm").subtract(appT.duration + 60, 'minutes').format("HH:mm")
            booking.end = moment(booking.endTime, "HH:mm").add(60, 'minutes').format("HH:mm")
          }
        })
      }

      // subtract bookings from slots, subtract blocks from slots
      let index = slots.length
      while (index--) {
        blocks.forEach(b => {
          if (moment(slots[index], 'YYYY-MM-DDTHH:mm:ssZZ').isBetween(moment(b.start, 'YYYY-MM-DDTHH:mm:ssZZ'), moment(b.end, 'YYYY-MM-DDTHH:mm:ssZZ'))) {
            slots.splice(index, 1); 
          }
        })

        if (bookings.length) {
          bookings.forEach(booking => {
            let momentSlot = moment(slots[index], 'YYYY-MM-DDTHH:mm:ssZZ')
            let momentBookingStart = moment(day + booking.start, 'YYYY-MM-DDHH:mm')
            let momentBookingEnd = moment(day + booking.end, 'YYYY-MM-DDHH:mm')

             if (booking.id !== this.booking.id) {
               if ( momentSlot.isBetween(momentBookingStart, momentBookingEnd )) {
                slots.splice(index, 1)
              }
             }
          })
        }
      }

      const formattedSlots = slots.map(el => {
        return moment(el, 'YYYY-MM-DDTHH:mm:ssZZ').format("HH:mm")
      })
      this.newAv = formattedSlots
      this.loadingTimes = false
    },
    rescheduleAppointment() {
      let app = {
        datetime:
          moment(this.booking.datetime).format("YYYY-MM-DD") +
          "T" +
          this.selectedTime
      };
      this.$emit("rescheduleAppointment", this.$route.params.id, app);
      this.$router.push({ name: "dashboard" });
    },
    cancelAppointment() {
      this.$emit("cancelAppointment", this.$route.params.id);
      this.$router.push({ name: "dashboard" });
    }
  },
  computed: {
    ...mapState(["userAps", "userProfile", "appointmentTypes"]),
    booking() {
      let b = this.userAps.filter(el => {
        return el.id == this.$route.params.id;
      });
      return b[0];
    },
    formattedAv() {
      return this.newAv.map(el => {
        return moment(el.time).format("HH:mm");
      });
    }
  }
};
</script>

<style scoped>
.modal {
  width: 50%;
  background: #f5f5f5;
  z-index: 5;
  position: relative;
  border-radius: 20px;
  margin-bottom: 100px;
}

.header {
  color: #f0f0f0;
  font-size: 20px;
  padding: 20px;
  border-radius: 20px 20px 0 0;
  background: #00838F;
  display: flex;
  justify-content: space-between;
}

.content {
  padding: 20px;
}

.disabled {
  background: rgb(182, 182, 182);
  color: #f0f0f0;
  pointer-events: none;
}

.selects-container {
  display: flex;
  align-items: center;
}

.full-width {
  width: 100%;
}

.uberschrift {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 5px;
}

.bold {
  font-weight: 700;
}

.light {
  font-weight: 400;
}

.bottom-button {
  text-align: center;
}

.buttons {
  display: flex;
  justify-content: center;
}

.warn {
  background: #A6443B;
  border-color: #A6443B;
}

.sel-small {
  width: auto;
  margin-bottom: 0px;
}

.btnLight {
  background: transparent;
  color: #2c3e50;
}

.closeEdit {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

</style>
<template>
  <div class="modal">
    <div class="header">
      <div class="bold">{{successMessage}}</div>
      <div @click="close" class="pointer"><span class="material-icons icon bold" style="color: #f0f0f0">close</span></div>
    </div>

    <div class="content">
      <div style="margin-bottom: 20px">
        <div class="uberschrift underline">Buchung</div>
        <div class="uberschrift light"> <span class="bold">{{newBooking.type}}</span> mit <span class="bold">{{newBooking.calendar}}</span> am <span class="bold">{{newBooking.date}}</span> von <span class="bold">{{newBooking.time}} bis {{newBooking.endTime}}</span> Uhr.</div>
        <div class="uberschrift light" v-if="newBooking.forms[1]"><span class="bold" v-if="newBooking.forms[1].values[0].value">Anmerkungen: </span>{{newBooking.forms[1].values[0].value}}</div>
      </div>
      <div class="buttons">
        <button @click="close" class="btn bearbeiten">Schließen</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  props: ["newBooking", "successMessage"],
  methods: {
    close() {
      this.$emit("closeBookingSuccess");
    }
  },
  computed: {
    ...mapState(["appointmentTypes"]),
  }
};
</script>

<style scoped>
.modal {
  width: 50%;
  background: #f5f5f5;
  z-index: 5;
  position: relative;
  border-radius: 20px;
  margin-bottom: 100px;
}

.header {
  color: #f0f0f0;
  font-size: 20px;
  padding: 20px;
  border-radius: 20px 20px 0 0;
  display: flex;
  justify-content: space-between;
  background: #4ab863;
}

.content {
  padding: 20px;
}

.bold {
  font-weight: 700;
}

.disabled {
  background: rgb(182, 182, 182);
  color: #f0f0f0;
  pointer-events: none;
}

.selects-container {
  display: flex;
}

.uberschrift {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 5px;
}

.infos {
  margin-bottom: 10px;
  border-radius: 2px;
  background: transparent;
  font-family: 'Karla';
  padding: 10px;
  border-color:#2c3e50;
  outline: none;
  width: calc( 100% - 20px );
}

.buttons {
  display: flex;
  justify-content: center;
}

.light {
  font-weight: 400;
}

.underline {
  font-size: 1.2em;
  width: 100%;
  border-bottom: 1px solid #2c3e50;
  margin-bottom: 20px;
}
</style>
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import deLocale from '@fullcalendar/core/locales/de'
import interactionPlugin from '@fullcalendar/interaction'

const fullCalConfig = {
  calendarPlugins: [dayGridPlugin, interactionPlugin, timeGridPlugin],
  deLocale,
  header: {
    left: '',
    center: '',
    right: ''
  },
  views: {
    timeGridSevenDay: {
      type: 'timeGrid',
      duration: {
        days: 7
      },
      buttonText: '7 Tage',
      allDaySlot: false,
      slotDuration: '00:15:00',
      minTime: '08:00',
      maxTime: '20:00',
      nowIndicator: true,
      slotDuration: '00:30:00',
      slotLabelInterval: '01:00:00',
      scrollTime: new Date().toLocaleTimeString()
    },
    timeGridWeek: {
      allDaySlot: false,
      minTime: '10:00',
      maxTime: '20:00',
      nowIndicator: true,
      slotDuration: '00:30:00',
      slotLabelInterval: '01:00:00',
      scrollTime: new Date().toLocaleTimeString()
    },
    timeGridDay: {
      allDaySlot: false,
      minTime: '06:00',
      maxTime: '23:00',
      nowIndicator: true,
      slotDuration: '00:15:00',
      slotLabelInterval: '00:15:00',
      scrollTime: new Date().toLocaleTimeString()
    }
  },
  slotLabelFormat: {
    hour: 'numeric',
    minute: '2-digit',
    omitZeroMinute: false,
    meridiem: false,
  },
 /*  columnHeaderFormat: {
    day: '2-digit'
  } */
}

export default fullCalConfig
<template>
  <div>
    <header>
      <section>
        <div class="nav">
          <div>
            <router-link v-bind:to="{ name: 'dashboard' }">
              <h1 class="logo" @click="reload">Get in Touch</h1>
            </router-link>
           <!--  <transition appear name="fade">
              <div class="m-y">Welcome {{userProfile.name}}</div>
            </transition> -->
          </div>
          <transition appear name="fade">
            <div>
              <ul class="links">
                <!-- <li class="nav-item">
                  <router-link v-bind:to="{ name: 'dashboard' }">Dashboard</router-link>
                </li> -->
                <li class="nav-item">
                  <a @click="logout">Logout</a>
                </li>
              </ul>
            </div>
          </transition>
        </div>
        <transition appear name="slide">
          <div class="line"></div>
        </transition>
      </section>
    </header>
  </div>
</template>

<script>
const fb = require("../fbConfig.js");
import { mapState } from "vuex";
import { currentUser } from "../fbConfig";

export default {
  data() {
    return {
      slide: false
    };
  },
  computed: {
    ...mapState(["userProfile", "currentUser"])
  },
  methods: {
    logout() {
      fb.auth
        .signOut()
        .then(() => {
          this.$store.dispatch("clearData");
          this.$router.push("/login");
        })
        .catch(err => {
          console.log(err);
        });
    },
    reload() {
      this.$router.go()
    }
  }
};
</script>

<style scoped>
.nav {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  height: 30px;
}

.logo {
  font-family: "Raleway", Helvetica, Arial, sans-serif;
  font-weight: 300;
  letter-spacing: 2px;
  margin: 0;
}

.links {
  display: flex;
}

.nav-item a {
  padding: 0 10px 0 0;
  cursor: pointer;
}

.links .router-link-exact-active {
  font-weight: bold;
}

.profile h4 {
  margin: 0;
}

.line {
  height: 1px;
  background-color: #2c3e50;
  width: 100%;
}

.m-y {
  margin: 5px 0;
}

.slide-enter-active,
.slide-leave-active {
  transition: all 0.4s ease-in;
}
.slide-enter,
.slide-leave-to {
  width: 0;
  transform: translateX(100px);
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.7s ease-in;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
import axios from 'axios'

const apiClient = axios.create({
  baseURL: `https://get-in-touch-client.herokuapp.com/`,
  // baseURL: `http://localhost:8000/`,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

export default {
  getAvByCal(appType, calId, date) {
    return apiClient.get('/availability/' + appType + '/' + calId + '/' + date)
  },

  getAvByDate(appType, date) {
    return apiClient.get('/availability/' + appType + '/' + date)
  },

  getAvByCalIgnore(appType, calId, date, appointmentId) {
    return apiClient.get('/availability/' + appType + '/' + calId + '/' + date + '/' + appointmentId)
  },

  getBlocks(calId, min, max) {
    return apiClient.get('/blocks/' + calId + '/' + min + '/' + max)
  },

}
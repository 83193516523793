<template>
  <div id="login">
    
    <section class="login">
      <div v-if="performingRequest" class="loading">
        <p>Loading....</p>
      </div>

      <div v-if="showLoginForm && !performingRequest" class="form-container">
        <h3>Willkommen zurück</h3>
        <form @submit.prevent class="login-form">
          <input
            class="login-input"
            v-model.trim="loginForm.email"
            type="text"
            placeholder="E-mail Adresse"
            id="email1"
            autocomplete="username"
          />

          <input
            class="login-input"
            v-model.trim="loginForm.password"
            type="password"
            placeholder="Passwort"
            id="password1"
            autocomplete="current-password"
            v-on:keyup.enter="login"
          />
        </form>
          <div class="extras">
            <button @click="login" class="btn activate">Login</button>
            <button @click="togglePasswordReset" class="btn switch">Passwort vergessen</button>
          </div>
      </div>

      <div v-if="showForgotPassword && !performingRequest" class="form-container">
        <h3>Passwort zurücksetzen</h3>
        <p>Sie bekommen ein E-Mail mit Informationen <br> zum Zurücksetzen Ihres Passworts.</p>
        <div v-if="!passwordResetSuccess">
          <form @submit.prevent class="login-form">
            <input 
              class="login-input"
              type="text" 
              v-model.trim="passwordForm.email" 
              placeholder="E-mail Adresse" 
              id="email3" />
          </form>
          <div>
            <button class="btn activate" @click="resetPassword">Senden</button>
            <button class="btn switch" @click="togglePasswordReset">Zurück zum Login</button>
          </div>
        </div>
        <div v-else class="login-form">
          <p>E-mail versendet!</p>
          <button @click="togglePasswordReset" class="btn switch">Zurück zum Login</button>
        </div>
      </div>

      <div v-if="errorMsg !== ''" class="error-msg">
        <p>
          {{ errorMsg }}
          <span class="material-icons icon bold close-error" @click="errorMsg = ''">close</span>
        </p>
      </div>
    </section>
  </div>
</template>

<script>
const fb = require("../fbConfig");

export default {
  data() {
    return {
      loginForm: {
        email: "",
        password: ""
      },
      passwordForm: {
        email: ""
      },
      showLoginForm: true,
      showForgotPassword: false,
      passwordResetSuccess: false,
      performingRequest: false,
      errorMsg: "",
      calId: []
    };
  },
  methods: {
    login() {
      this.performingRequest = true;
      fb.auth
        .signInWithEmailAndPassword(
          this.loginForm.email,
          this.loginForm.password
        )
        .then(user => {
          this.$store.commit("setCurrentUser", user.user);
          this.$store.dispatch("fetchUserProfile").then(profile => {
            if ((profile.role === "Hotel") | (profile.role === "admin")) {
              this.performingRequest = false;
              this.$router.push({ name: "dashboard" });
            } else {
              this.$store.dispatch("clearData");
              this.errorMsg = "Nicht autorisiert";
              this.performingRequest = false;
            }
          });
        })
        .catch(err => {
          console.log(err);
          this.performingRequest = false;
          this.errorMsg = err.message;
        });
    },
    resetPassword() {
      this.performingRequest = true;

      fb.auth
        .sendPasswordResetEmail(this.passwordForm.email)
        .then(() => {
          this.performingRequest = false;
          this.passwordResetSuccess = true;
          this.passwordForm.email = "";
        })
        .catch(err => {
          console.log(err);
          this.performingRequest = false;
          this.errorMsg = err.message;
        });
    },
    toggleForm() {
      this.errorMsg = "";
      this.showLoginForm = !this.showLoginForm;
    },
    togglePasswordReset() {
      if (this.showForgotPassword) {
        this.showLoginForm = true;
        this.showForgotPassword = false;
        this.passwordResetSuccess = false;
        this.errorMsg = ""
      } else {
        this.showLoginForm = false;
        this.showForgotPassword = true;
        this.errorMsg = ""
      }
    }
  }
};
</script>

<style scoped>
.logo {
  font-family: "Raleway", Helvetica, Arial, sans-serif;
  font-weight: 300;
  letter-spacing: 2px;
  margin: 0;
  font-size: 25px;
}

h3 {
  font-weight: normal;
  font-size: 1.7em;
  margin: 0;
}

.line {
  height: 1px;
  background-color: #2c3e50;
  width: 100%;
}

.login {
  display: flex;
  height: 80vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.form-container {
  background: #fcfcfc;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 20px;
  padding: 50px;
}

.login-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 40px;
  margin-top: 20px;
}

input {
  margin: 10px;
}

.login-input {
  outline: none;
  background: transparent;
  border: none;
  border-bottom: 1px solid #2c3e50;
  padding: 10px;
  font-family: "Karla", Helvetica, Arial, sans-serif;
  font-size: 1em;
}

.disabled {
  color: rgba(141, 141, 141, 0.466);
  background-color: rgba(209, 209, 209, 0.466);
  border-color: rgba(141, 141, 141, 0.466);
  cursor: auto;
}

.activate {
  background-color: #2c3e50;
  padding: 7px 10px;
  color: #fcfcfc;
  font-weight: 700;
  min-width: 180px;
  margin-right: 20px;
}

.switch {
  font-weight: 700;
  background-color: #798896;
  border-color: #798896;
  color:#F0F0F0;
  padding: 7px 10px;
  min-width: 180px;
}

.error-msg {
  position: absolute;
  bottom: 50px;
  background: #a6443b;
  color: #F0F0F0;
  padding: 0px 20px;
  margin-top: 20px;
  border-radius: 5px;
  font-weight: bold;
}

.close-error {
  color: #f0f0f0; 
  margin-left: 10px;
  cursor: pointer;
}

.loading {
  position: absolute;
  bottom: 50px;
  background: #757575;
  color: #F0F0F0;
  padding: 0px 20px;
  margin-top: 20px;
  border-radius: 5px;
  font-weight: bold;
}

</style>
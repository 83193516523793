import axios from 'axios'

const apiClient = axios.create({
  baseURL: `https://get-in-touch-client.herokuapp.com/`,
  // baseURL: `http://localhost:8000/`,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

export default {
  getApsforUser(email) {
    return apiClient.get('/appointments/' + email)
  },

  getCals() {
    return apiClient.get('/calendars/')
  },

  getAppTypes() {
    return apiClient.get('/appointment-types/')
  },

  getApsforCal(id, min, max) {
    return apiClient.get('/calendars/' + id + '/' + min + '/' + max)
  },

  checkTimes(time) {
    return apiClient.post('/checktimes', time)
  },

  newApp(data) {
    return apiClient.post('/newapp', data)
  },

  newAppAsAdmin(data) {
    return apiClient.post('/newapp-admin', data)
  },

  reschedule(id, app) {
    return apiClient.put('/reschedule-admin/' + id, app)
  },

  cancel(id) {
    return apiClient.put('/cancel/' + id)
  },

  // test 
  weekAvforCal(appType, id, start, email, duration) {
    return apiClient.get('/weekav/' + appType + '/' + id + '/' + start + '/' + email + '/' + duration)
  }
}
import Vue from 'vue'
import VueRouter from 'vue-router'
import firebase from 'firebase/app'

import Login from '@/components/Login'
import Dashboard from '@/components/Dashboard'
import Book from '@/components/Book'
import Booking from '@/components/Booking'

import Dashplay from '@/components/Dashboard_copy'

Vue.use(VueRouter)

const routes = [{
    path: '/login',
    name: 'login',
    component: Login
  },
  {
    path: '/',
    name: 'dashboard',
    component: Dashboard,
    meta: {
      requiresAuth: true
    },
    children: [{
        path: 'book',
        name: 'book',
        component: Book,
        meta: {
          requiresAuth: true
        }
      },
      {
        path: 'booking/:id',
        name: 'booking',
        component: Booking,
        meta: {
          requiresAuth: true
        }
      }
    ]
  },
  {
    path: '/play',
    name: 'dashplay',
    component: Dashplay,
    meta: {
      requiresAuth: true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(x => x.meta.requiresAuth)
  const currentUser = firebase.auth().currentUser

  if (requiresAuth && !currentUser) {
    next('/login')
  } else if (requiresAuth && currentUser) {
    next()
  } else {
    next()
  }
})

export default router
<template>
  <div>
    <div v-if="!this.loading">
      <div class="db">
        <div class="calendar">
          <div class="cal-header">
            <div class="header-section">
              <span class="headline" @click="openDp">{{title}}</span>
              <flat-pickr
                v-model="dpDate"
                :config="config"
                class="hide-me"
                @input="goToDate"
                ref="Datepicker"
              ></flat-pickr>
              <button class="btn" @click="openDp"><span class="material-icons icon" style="padding: 0px 0px;">calendar_today</span></button>
              <button class="btn" @click="chv">{{view}}</button>
            </div>
            <div>
              <button class="btn" :class="{ disabled: prevDisabled}" @click="prev"><span class="material-icons icon">navigate_before</span></button>
              <button class="btn" :class="{ disabled: nextDisabled}" @click="nxt"><span class="material-icons icon">navigate_next</span></button>
              <button class="btn btn-text" @click="today">Heute</button>
              <button class="btn btn-text" @click="refresh"><span class="material-icons icon">refresh</span></button>
            </div>
          </div>
          <div v-bind:class="{ calloading: calLoading}">
            <div v-if="calLoading" class="spinner">
              <Spinner line-fg-color="#2c3e50" line-bg-color="#fff" />
            </div>
            <FullCalendar
              ref="calendar"
              defaultView="timeGridSevenDay"
              height="auto"
              :validRange="visRange"
              :slotLabelFormat="slotLabelFormat"
              :datesRender="getDate"
              :eventOverlap="false"
              :locale="deLocale"
              :plugins="calendarPlugins"
              :weekends="true"
              :views="views"
              :header="false"
              @eventClick="eventClick"
              :eventSources="[
            events,
            testWeekAv
          ]"
            />
          </div>
        </div>
        <div class="booking">
          <div class="booking-content" v-bind:class="{ calloading: calLoading}">
            <div class="booking-header">Behandlung</div>
            <div>
              <select v-model="selectedAppType" class="sel" @change="appTypeOrWeekChange">
                <option v-for="appType in appointmentTypes" :value="appType.id">{{ appType.name }}</option>
              </select>
            </div>

            <div class="booking-header">Therapeut*innen</div>
            <div class="therapists">
              <div
                v-for="cal in cals"
                :key="cal.id"
                class="therapist"
                :style="checkedTherapist.includes(cal.id) ? { background: cal.color } : { background: cal.color + '40', color: 'grey', opacity: '0.7'}"
                @click="calcWeekAvForCal(cal.id, cal.name, cal.color)"
              >
                <img :src="'http:' + cal.thumbnail" alt class="thumbnail" />
                <label class="check-lable" for="therapist">{{cal.name}}</label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="buchungen">
        <div class="cal-header">
          <div v-if="currentBookings" @click="currentBookings = !currentBookings" class="headline with-link">Aktuelle Buchungen</div>
          <div v-else @click="currentBookings = !currentBookings" class="headline with-link">Vergangene Buchungen</div>
        </div>
        <div v-if="currentBookings">
          <table class="bookings-table" v-if="currentAppsSorted.length">
            <tr>
              <th>Datum</th>
              <th>Uhrzeit</th>
              <th>Therapeut*in</th>
              <th>Behandlung</th>
              <th></th>
            </tr>
            <tr v-for="app in currentAppsSorted" :key="app.id">
              <td>{{app.date}}</td>
              <td>{{app.time}} - {{app.endTime}}</td>
              <td>{{app.calendar}}</td>
              <td>{{app.type}}</td>
              <td>
                <button
                  v-if="app.canClientReschedule || app.canClientCancel"
                  class="btn bearbeiten"
                  :id="app.id"
                  @click="bookingBearbeiten">Bearbeiten</button>
                <span v-else>–</span>
              </td>
            </tr>
          </table>
          <div v-else>
            Derzeit keine aktuellen Buchungen.
          </div>
        </div>
        <div v-else>
          <table class="bookings-table" v-if="pastAppsSorted">
            <tr>
              <th>Datum</th>
              <th>Uhrzeit</th>
              <th>Therapeut*in</th>
              <th>Behandlung</th>
            </tr>
            <tr v-for="app in pastAppsSorted" :key="app.id">
              <td>{{app.date}}</td>
              <td>{{app.time}} - {{app.endTime}}</td>
              <td>{{app.calendar}}</td>
              <td>{{app.type}}</td>
            </tr>
          </table>
          <div v-else>
            Keine vergangenen Buchungen vorhanden.
          </div>
        </div>
      </div>

      <div class="bg-modal" v-if="isBookOpen" @click.self="close">
        <Book
          :selectedAppType="selectedAppType"
          :appTypes="appTypes"
          @createAppointment="createAppointment"
        />
      </div>

      <div class="bg-modal" v-if="sucessfulBooking" @click.self="sucessfulBooking = false">
        <BookingSuccess
          :newBooking="newBooking"
          @closeBookingSuccess="closeBookingSuccess"
          :successMessage="successMessage"
        />
      </div>

      <div class="bg-modal" v-if="isBookingOpen" @click.self="close">
        <Booking
          :appTypes="appTypes"
          @rescheduleAppointment="rescheduleAppointment"
          @cancelAppointment="cancelAppointment"
        />
      </div>

      <div class="bg-modal" v-if="errorMessage" @click.self="errorMessage = null">
        <Error
          @closeError="errorMessage = null"
          :errorMessage="errorMessage"
        />
      </div>
    </div>
    <div v-else>
      <div class="spinner">
        <Spinner line-fg-color="#2c3e50" line-bg-color="#fff" />
      </div>
    </div>
  </div>
</template>

<script>
import FullCalendar from "@fullcalendar/vue";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import deLocale from "@fullcalendar/core/locales/de";
import interactionPlugin from "@fullcalendar/interaction";
import "@fullcalendar/core/main.css";
import "@fullcalendar/daygrid/main.css";
import "@fullcalendar/timegrid/main.css";
import moment from "moment";
import fcConfig from "../fullCalConfig";
import { mapState } from "vuex";
import Book from "./Book";
import BookingSuccess from "./BookingSuccess";
import Booking from "./Booking";
import Error from "./Error";
import AppointmentService from "../services/AppointmentService";

import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { German } from "flatpickr/dist/l10n/de.js";
import Spinner from "vue-simple-spinner";

export default {
  components: {
    FullCalendar,
    Book,
    BookingSuccess,
    Booking,
    flatPickr,
    Spinner,
    Error
  },
  data() {
    return {
      calendarPlugins: [dayGridPlugin, interactionPlugin, timeGridPlugin],
      ...fcConfig,
      title: "",
      loading: false,
      view: "W",
      checkedTherapist: [],
      testWeekAv: [],
      selectedAppType: "12738807",
      appTypes: [
        {
          text: "Hotels Massage 60 Min. API Test",
          value: "12738807",
          duration: 60
        },
        {
          text: "Hotels Massage 90 Min. API Test",
          value: "12738840",
          duration: 90
        }
      ],
      bookOpen: false,
      calLoading: false,
      currentBookings: true,
      sucessfulBooking: false,
      newBooking: null,
      dpDate: moment().format("YYYY-MM-DD"),
      config: {
        locale: German,
        disableMobile: true,
        wrap: true,
        altInputClass: "hide-me",
        minDate: moment().format("YYYY-MM-DD"),
        maxDate: moment()
          .add(2, "months")
          .subtract(1, "days")
          .format("YYYY-MM-DD")
      },
      prevDisabled: false,
      nextDisabled: false,
      successMessage: "",
      errorMessage: null
    };
  },
  methods: {
    eventClick(info) {
      let title = info.event.title.toLowerCase()
      if (!title.includes("massage")) {
        let cal = this.cals.find(el => {
          return el.name === info.event.title;
        });
        this.$router.push({
          name: "book",
          params: {
            calName: info.event.title,
            calId: cal.id,
            calColor: cal.color,
            start: info.event.start
          }
        });
      } else {
        this.$router.push({ name: "booking", params: { id: info.event.id } });
      }
    },
    bookingBearbeiten(e) {
      let id = e.target.id;
      this.$router.push({ name: "booking", params: { id } });
    },
    testCal() {
      console.log(this.$refs.calendar.getApi().view);
    },
    chv() {
      let currentview = this.$refs.calendar.getApi().view.type;
      if (currentview === "timeGridSevenDay") {
        this.$refs.calendar.getApi().changeView("timeGridWeek");
        this.view = "7";
      } else {
        this.$refs.calendar.getApi().changeView("timeGridSevenDay");
        this.view = "W";
      }
    },
    nxt() {
      if (!this.nextDisabled) {
        this.$refs.calendar.getApi().next();
        this.appTypeOrWeekChange();
      }
    },
    today() {
      this.$refs.calendar.getApi().today();
      this.appTypeOrWeekChange();
    },
    prev() {
      if (!this.prevDisabled) {
        this.$refs.calendar.getApi().prev();
        this.appTypeOrWeekChange();
      }
    },
    getDate(info) {
      this.title = info.view.title;
      this.prevDisabled = moment(info.view.activeStart).isSameOrBefore(
        moment()
      );
      this.nextDisabled = moment(info.view.activeEnd).isSameOrAfter(
        moment()
          .add(2, "months")
          .subtract(1, "days")
      );
    },
    goToDate() {
      this.$refs.calendar.getApi().gotoDate(this.dpDate);
      this.appTypeOrWeekChange();
    },
    openDp() {
      this.$refs.Datepicker.fp.open();
    },
    visRange() {
      let start = moment().format("YYYY-MM-DD");
      let end = moment()
        .add(2, "months")
        .format("YYYY-MM-DD");
      return { start: start, end: end };
    },
    getDays() {
      var weekStart = this.$refs.calendar ? moment(this.$refs.calendar.getApi().view.activeStart) : moment()
      var days = [];
      for (let i = 0; i <= 6; i++) {
        days.push(
          moment(weekStart)
            .add(i, "days")
            .format("YYYY-MM-DD")
        );
      }
      return days;
    },
    async appTypeOrWeekChange() {
      this.testWeekAv = []
      this.calLoading = true
      for (const el of this.checkedTherapist) {
        let cal = this.cals.filter(cal => {
          return cal.id == el
        })
        const week = await this.calcWeekSlots(cal[0].id, cal[0].name, cal[0].color)
        console.log(cal[0].name, 'week', week)

        // push av to cal
        week.forEach(el => {
          if (el.events) {
            el.events.forEach(e => {
              this.testWeekAv.push(e)
            })
          }
        })
      }
      this.calLoading = false
    },
    get10MinSlots(day, startTime) {
      const start = moment(day + startTime, 'YYYY-MM-DDHH:mm');
      const end = moment(day + '22:00:00', 'YYYY-MM-DDHH:mm');
      const timeSeries = [];

      while (start.isSameOrBefore(end)) {
        timeSeries.push(start.add(10, 'm').format('YYYY-MM-DDTHH:mm:ssZZ'));
      }
      return timeSeries
    },
    getDayAv(day, name, color) {
      // console.log('calculating events for', name)
      if (day.length) {
        let starts = [];
        let ends = [];
        day.forEach((el, index) => {
          if (
            day[index + 1] &&
            moment(el.time).diff(moment(day[index + 1].time), "minutes") < -10
          ) {
            ends.push(el.time);
            starts.push(day[index + 1].time);
          }
        });
        starts.unshift(day[0].time);
        ends.push(day[day.length - 1].time);
        starts.map((el, index) => {
          this.testWeekAv.push({
            start: el,
            end: ends[index],
            color: color,
            title: name,
            textColor: "#2c3e50"
          });
        });
      }
    },
    getSelectedAppTypeFull() {
      let appT = this.appointmentTypes.filter(el => {
        return el.id== this.selectedAppType
      })
      return appT[0]
    },
    getBlocksForWeek(calId, min, max) {
      return this.$store.dispatch("fetchBlocksForCal", { id: calId, min: min, max: max})
    },
    getBookingsForWeek(calId, min, max) {
      return this.$store.dispatch("fetchApsForCal", { id: calId, min: min, max: max})
    },
    eventsFormSlots(day, name, color) {
      if (day.slots.length) {
        let starts = []
        let ends = []
        day.slots.forEach((el, index) => {
          if (
            day.slots[index + 1] &&
            moment(el).diff(moment(day.slots[index + 1]), "minutes") < -10
          ) {
            ends.push(el)
            starts.push(day.slots[index + 1])
          }
        });
        starts.unshift(day.slots[0]);
        ends.push(day.slots[day.slots.length - 1])

        let evs = starts.map((el, index) => {
          return {
            start: el,
            end: ends[index],
            color: color,
            title: name,
            textColor: "#2c3e50"
          }
        })
        day.events = evs
      }
      return day
    },
    async calcWeekSlots(calId, name, color) {
      let days = this.getDays()

      const selectedAppType = this.getSelectedAppTypeFull()

      // get blocks for week
      await this.getBlocksForWeek(calId, days[0], days[days.length-1])
      const blocks = JSON.parse(JSON.stringify(this.$store.state.calBlocks))

      // augment blocks
      blocks.forEach(el => {
        // el.end = moment(el.end, 'YYYY-MM-DDTHH:mm:ssZZ').add(selectedAppType.duration + 10, 'minutes').format('YYYY-MM-DDTHH:mm:ssZZ')
        el.start = moment(el.start, 'YYYY-MM-DDTHH:mm:ssZZ').subtract(selectedAppType.duration + 10, 'minutes').format('YYYY-MM-DDTHH:mm:ssZZ')
      })

      // get bookings for week
      await this.getBookingsForWeek(calId, days[0], days[days.length-1])
      const bookings = JSON.parse(JSON.stringify(this.$store.state.calAps))

      // create empty week
      let week = [{blocks: [], bookings: []},{blocks: [], bookings: []},{blocks: [], bookings: []},{blocks: [], bookings: []},{blocks: [], bookings: []},{blocks: [], bookings: []},{blocks: [], bookings: []}]

      // sort api data into week array
      days.forEach((day, i) => {

        // sort blocks
        blocks.forEach(block => {
          if (moment(block.start, 'YYYY-MM-DDTHH:mm:ssZZ').format('YYYY-MM-DD') === day) {
            week[i].blocks.push(block)
          }
        })
        // sort bookings
        bookings.forEach(booking => {
          if (moment(booking.datetime, 'YYYY-MM-DDTHH:mm:ssZZ').format('YYYY-MM-DD') === day) {
            week[i].bookings.push(booking)
          }
        })
      })

      // start loop
      for (const [i, day] of week.entries()) {
        if (day.bookings.length) {

          // get day slots
          if (days[i] === moment().format('YYYY-MM-DD')) {
            let start = moment().add(2, 'hours')
            let remainder = 10 - (start.minute() % 10)
            const startTime = moment(start)
              .add(remainder, "minutes")
              .format("HH:mm")
            day.slots = this.get10MinSlots(days[i], startTime)
          } else {
            day.slots = this.get10MinSlots(days[i], '06:00')
          }

          // augement bookings
          day.bookings.forEach(booking => {
            if (booking.email === this.userProfile.email) {
              // console.log('at hotel', booking)
              booking.start = moment(booking.time, "HH:mm").subtract(selectedAppType.duration + 10, 'minutes').format("HH:mm")
              booking.end = moment(booking.endTime, "HH:mm").add(10, 'minutes').format("HH:mm")
            } else if (booking.category === "API Test GiT") {
              // console.log('at GIT', booking)
              booking.start = moment(booking.time, "HH:mm").subtract(selectedAppType.duration + 90, 'minutes').format("HH:mm")
              booking.end = moment(booking.endTime, "HH:mm").add(90, 'minutes').format("HH:mm")
            } else {
              // console.log('not at hotel', booking)
              booking.start =  moment(booking.time, "HH:mm").subtract(selectedAppType.duration + 60, 'minutes').format("HH:mm")
              booking.end = moment(booking.endTime, "HH:mm").add(60, 'minutes').format("HH:mm")
            }
          })

          // subtract bookings from slots, subtract blocks from slots
          let index = day.slots.length
          while (index--) {
            day.blocks.forEach(b => {
              if( moment(day.slots[index], 'YYYY-MM-DDTHH:mm:ssZZ').isBetween(moment(b.start, 'YYYY-MM-DDTHH:mm:ssZZ'), moment(b.end, 'YYYY-MM-DDTHH:mm:ssZZ'))) {
                day.slots.splice(index, 1); 
              }
            })

            day.bookings.forEach(booking => {
              let momentSlot = moment(day.slots[index], 'YYYY-MM-DDTHH:mm:ssZZ')
              // let momentSlotPlusDuration = moment(day.slots[index], 'YYYY-MM-DDTHH:mm:ssZZ').add(selectedAppType.duration, 'minutes')
              let momentBookingStart = moment(days[i] + booking.start, 'YYYY-MM-DDHH:mm')
              let momentBookingEnd = moment(days[i] + booking.end, 'YYYY-MM-DDHH:mm')
              // if (momentSlotPlusDuration.isBetween(momentBookingStart, momentBookingEnd) || momentSlot.isBetween(momentBookingStart, momentBookingEnd)) {
              if ( momentSlot.isBetween(momentBookingStart, momentBookingEnd )) {
                day.slots.splice(index, 1); 
              }
            })
          }
          this.eventsFormSlots(day, name, color)
        } else {
          // get day slots
          if (days[i] === moment().format('YYYY-MM-DD')) {
            let start = moment().add(2, 'hours')
            let remainder = 10 - (start.minute() % 10)
            const startTime = moment(start)
              .add(remainder, "minutes")
              .format("HH:mm")
            day.slots = this.get10MinSlots(days[i], startTime)
          } else {
            day.slots = this.get10MinSlots(days[i], '06:00')
          }

          // subtract bookings from slots, subtract blocks from slots
          let index = day.slots.length
          while (index--) {
            day.blocks.forEach(b => {
              if( moment(day.slots[index], 'YYYY-MM-DDTHH:mm:ssZZ').isBetween(moment(b.start, 'YYYY-MM-DDTHH:mm:ssZZ'), moment(b.end, 'YYYY-MM-DDTHH:mm:ssZZ'))) {
                day.slots.splice(index, 1); 
              }
            })
          }
          this.eventsFormSlots(day, name, color)
        }
      }
      return week
    },
    async pushAvToCal(calId, name, color) {
      // calc av for therapist
      this.calLoading = true
      const week = await this.calcWeekSlots(calId, name, color)
      console.log(name, 'week', week)

      // push av to cal
      week.forEach(el => {
        if (el.events) {
          el.events.forEach(e => {
            this.testWeekAv.push(e)
          })
        }
      })
      this.calLoading = false
    },
    async calcWeekAvForCal(calId, name, color) {
      if (this.checkedTherapist.includes(calId)) {
        this.checkedTherapist = this.checkedTherapist.filter(
          el => el !== calId
        );
        this.testWeekAv = this.testWeekAv.filter(el => el.title !== name);
      } else {
        this.checkedTherapist.push(calId)
        await this.pushAvToCal(calId, name, color)
      }
    },
    getApsForCal() {
      this.$store
        .dispatch("fetchApsForCal", {
          id: "3458940",
          min: moment(this.$refs.calendar.getApi().view.activeStart).format(
            "YYYY-MM-DD"
          ),
          max: moment(this.$refs.calendar.getApi().view.activeEnd).format(
            "YYYY-MM-DD"
          )
        })
        .then(() => {
          console.log("success")
        })
        .catch(error => {
          console.log(error)
        });
    },
    close() {
      this.$router.push({ name: "dashboard" });
    },
    closeBookingSuccess() {
      this.sucessfulBooking = false;
    },
    createAppointment(app) {
      this.calLoading = true;
      AppointmentService.newAppAsAdmin(app)
        .then(res => {
          if (!res.data.status_code) {
            this.newBooking = res.data;
            this.successMessage = "Buchung erfolgreich!"
            this.sucessfulBooking = true;
            this.$store
              .dispatch("fetchApsForUser", this.userProfile.email)
              .then(() => {
                this.$refs.calendar.getApi().refetchEvents()
                this.appTypeOrWeekChange();
            });
          } else {
            console.log(res.data.status_code, res.data.message)
            this.errorMessage = "Fehler beim Erstellen: " + res.data.message
            this.calLoading = false;
          }
        })
        .catch(error => {
          console.log(error);
          this.calLoading = false;
        });
    },
    rescheduleAppointment(id, app) {
      this.calLoading = true
      AppointmentService.reschedule(id, app)
        .then(res => {
          if (!res.data.status_code) {
            this.newBooking = res.data;
            this.successMessage = "Die Buchung wurde erfolgreich verschoben";
            this.sucessfulBooking = true;
            this.$store
              .dispatch("fetchApsForUser", this.userProfile.email)
              .then(() => {
                this.appTypeOrWeekChange();
            });
          } else {
            console.log(res.data.status_code, res.data.message)
            this.errorMessage = "Fehler beim Verschieben: " + res.data.message
            this.calLoading = false;
          }
        })
        .catch(error => {
          console.log(error);
          this.calLoading = false;
        });
    },
    cancelAppointment(id) {
      this.calLoading = true;
      AppointmentService.cancel(id)
        .then(res => {
          if (!res.data.status_code) {
            this.newBooking = res.data;
            this.successMessage = "Die Buchung wurde storniert";
            this.sucessfulBooking = true;
            this.$store
            .dispatch("fetchApsForUser", this.userProfile.email)
            .then(() => {
              this.appTypeOrWeekChange();
              this.calLoading = false;
            });
          } else {
            console.log(res.data.status_code, res.data.message)
            this.errorMessage = "Fehler beim Stornieren: " + res.data.message
            this.calLoading = false;
          }
        })
        .catch(error => {
          console.log(error);
          this.calLoading = false;
        });
    },
    refresh() {
      this.calLoading = true
      this.$store
        .dispatch("fetchApsForUser", this.userProfile.email)
        .then(() => {
          this.appTypeOrWeekChange();
          /* this.calLoading = false; */
        });
    }
  },
  computed: {
    ...mapState(["userProfile", "userAps", "avByCal", "cals", "calAps", "appointmentTypes"]),
    events() {
      return this.userAps.map(el => {
        // if( moment(el.datetime, 'YYYY-MM-DDTHH:mm:ssZ').isAfter(moment())) {}
        let format = "YYYY-MM-DD";
        let date = moment(el.date, "LLL", "de").format(format);
        return {
          title: el.type + " mit " + el.calendar,
          start: date + " " + el.time,
          end: date + " " + el.endTime,
          id: el.id,
          editable: false,
          color: "#00838F",
          textColor: "#F0F0F0"
        };
      });
    },
    currentAppsSorted() {
      let current = this.userAps.filter(el => {
        return moment(el.datetime).isSameOrAfter(moment());
      });
      return current.sort((a, b) => {
        return new Date(a.datetime) - new Date(b.datetime);
      });
    },
    pastAppsSorted() {
      let past = this.userAps.filter(el => {
        return moment(el.datetime).isSameOrBefore(moment());
      });
      return past.sort((a, b) => {
        return new Date(b.datetime) - new Date(a.datetime);
      });
    },
    isBookOpen() {
      return this.$route.name === "book";
    },
    isBookingOpen() {
      return this.$route.name === "booking";
    }
  },
  created() {
    this.loading = true
    this.$store.dispatch("fetchUserProfile").then(user => {
      this.$store.dispatch("fetchAppTypes").then((res) => {
        // console.log(user, res)
        let hotelAppTypes = res.filter(el => {
          return el.category == user.email
        })
        this.selectedAppType = hotelAppTypes[0].id
        this.$store.commit('setAppTypes', hotelAppTypes)
        console.log(this.appointmentTypes)
        this.$store.dispatch("fetchCalendars").then((res) => {
          let hotelCals = hotelAppTypes[0].calendarIDs
          let cals = res.filter((el) => hotelCals.includes(el.id));
          this.$store.commit('setCals', cals)

          cals.forEach(c => {
            this.checkedTherapist.push(c.id)
            this.pushAvToCal(c.id, c.name, c.color)
          }) 
          this.loading = false;
        });
      })
    })
  }
};
</script>

<style>
.db {
  padding: 20px 0 0 0;
  display: flex;
  align-items: center;
}

.btn {
  border: 1px solid #2c3e50;
  border-radius: 5px;
  font-family: "Karla";
  font-size: 1em;
  margin-right: 5px;
  background-color: transparent;
  outline: none;
  cursor: pointer;
  padding: 4px 10px;
}

.bearbeiten {
  font-weight: 700;
  background-color: #2c3e50;
  color:#F0F0F0;
  padding: 7px 10px;
}

.btn-text {
  font-weight: 400;
}

.cal-header {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  align-items: center;
}

.header-section {
  display: flex;
  align-items: center;
}

.booking-header {
  font-size: 25px;
  font-weight: 700;
  margin-bottom: 15px;
}

.sel {
  background: transparent;
  border: 1px solid #667685;
  border-radius: 2px;
  outline: none;
  width: calc(100% - 20px);
  font-family: "Karla";
  color: #2c3e50;
  padding: 5px 10px;
  font-size: 15px;
  margin-bottom: 15px;
  border-color: #2c3e50;
}

.headline {
  font-size: 40px;
  cursor: pointer;
}

.with-link:after {
  display:block;
  padding-bottom: 1px;
  content: '';
  border-bottom: solid 2px #2c3e50;
  transform: scaleX(0);  
  transition: transform 250ms ease-in-out;
}

.with-link:hover:after { 
  transform: scaleX(1); 
}

.calendar {
  flex: 4;
}

.booking {
  margin: 0 20px;
  flex: 1;
}

/* CAL */

.fc-day-header {
  font-size: 30px;
  text-align: left!important;
  font-weight: 400;
  padding: 5px!important;
  line-height: 40px;
}

.fc-view table {
  border: 0 hidden transparent;
}


.calendar .fc-widget-header {
  border: none;
}

/* .fc td, .fc th {
  border-style: none !important;
} */

.calendar .fc-widget-content {
  /* border-bottom: 1px solid #e0e0e0!important; */
  border-bottom: 0 hidden transparent!important;
}

.fc-day-header {
  border-left: 1px solid #2c3e50!important;
}

.fc-slats {
  border-bottom: 1px solid #2c3e50!important;
}

.fc-unthemed td.fc-today {
  background: #dbdbdb;
}

.calloading .fc-unthemed td.fc-today {
  background: #a1a1a134;
}

.fc-event {
  border: none;
  margin: 2px;
  margin-right: 0px;
  border-radius: 5px;
  padding: 2px;
  box-shadow: 5px 5px 6px #162a983d;
  line-height: 13px;
  cursor: pointer;
}

.calendar .fc-widget-content .fc-minor {
  background: #bfbfbf33;
}

/* .fc-time {
  color: #2c3e50;
} */

.fc-title {
  font-size: 0.8em;
 /*  color: #2c3e50; */
}

.fc-day-header.fc-widget-header.fc-disabled-day {
  background: transparent;
}

/* End CAL */

.buchungen {
  margin-top: 15px;
}

.bookings-table {
  margin-top: 20px;
  width: 100%;
  border-collapse: collapse;
  font-size: 0.9em;
  margin-bottom: 50px;
}

td, th {
  border-color: #2c3e50 !important;
}

.bookings-table th {
  border-bottom: 1px solid #2c3e50;
  padding: 5px;
}

 .bookings-table td {
  font-size: 0.9em;
  text-align: center;
  border-bottom: 1px solid #2c3e50;
  padding: 5px 0px;
}

 .bookings-table tr {
   height: 40px;
 }

.bg-modal {
  height: 100vh;
  width: 100vw;
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(155, 155, 155, 0.466);
  z-index: 4;
}

.calloading {
  background-color: #F0F0F0;
  position: relative;
  z-index: 2;
  pointer-events: none;
}

.spinner {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 3;
}

.thumbnail {
  width: 50px;
  height: 50px;
  border-radius: 50%;
}

.therapists {
  display: flex;
  flex-direction: column;
  padding-right: 20px;
}

.therapist {
  display: flex;
  align-items: center;
  border-radius: 5px;
  margin: 7px 0;
  padding: 5px;
  color: #2c3e50;
  font-size: 1.1em;
  cursor: pointer;
}

.therapist label {
  margin-left: 10px;
  font-size: 0.9em;
  cursor: pointer;
}


.hide-me {
  visibility: hidden;
  width: 0;
}

.flatpickr-calendar {
  font-family: "Karla";
}

.flatpickr-day,
.flatpickr-current-month {
  color: #2c3e50;
}

.flatpickr-day.selected {
  background: #2c3e50;
  border-color: #2c3e50;
}

.disabled {
  color: rgba(141, 141, 141, 0.466);
  background-color: rgba(209, 209, 209, 0.466);
  border-color: rgba(141, 141, 141, 0.466);
  cursor: auto;
}

.icon {
  vertical-align: bottom;
  font-size: 19px;
  color: #2c3e50;
}

.pointer {
  cursor: pointer;
}

/* FLATPICKER */

.flatpickr-calendar {
  border: 1px solid #2c3e50;
  background: #f5f5f5;
}

.numInputWrapper {
  display: none;
}

/* END FLATPICKER */

.cheap-loading {
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2.5em;
}

</style>
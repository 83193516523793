<template>
  <div id="app">
    <Nav v-if="currentUser" id="nav" />
    <div v-else>
      <h1 class="logo">Get in Touch</h1>
      <div class="line"></div>
    </div>
    <router-view />
  </div>
</template>

<script>
import { mapState } from "vuex";
import Nav from "./components/Nav";

export default {
  components: { Nav },
  computed: {
    ...mapState(["currentUser", "userProfile"])
  }
};
</script>

<style lang="scss">
body,
html {
  margin: 0;
  background: #F0F0F0;
}

#app {
  font-family: "Karla", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  padding: 20px;
}

#nav {
  ul {
    list-style-type: none;
    margin: 0;
  }

  a {
    text-decoration: none;
  }

  a:visited {
    color: #2c3e50;
  }
}

.logo {
  font-family: "Raleway", Helvetica, Arial, sans-serif;
  font-weight: 300;
  letter-spacing: 2px;
  margin: 0;
  font-size: 25px;
}

h3 {
  font-weight: normal;
  font-size: 1.7em;
  margin: 0;
}

.line {
  height: 1px;
  background-color: #2c3e50;
  width: 100%;
}
</style>

<template>
  <div class="modal">
    <div class="header">
      <div>Error</div>
      <div @click="close" class="pointer"><span class="material-icons icon bold" style="color: #f0f0f0">close</span></div>
    </div>
    <div class="content">
      <div class="uberschrift">{{errorMessage}}</div>
      <div class="buttons">
        <button @click="close" class="btn bearbeiten">Schließen</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["errorMessage"],
  methods: {
    close() {
      this.$emit("closeError");
    }
  }
};
</script>

<style scoped>
.modal {
  width: 50%;
  background: #f5f5f5;
  z-index: 5;
  position: relative;
  border-radius: 20px;
  margin-bottom: 100px;
}

.header {
  color: #f0f0f0;
  font-size: 20px;
  padding: 20px;
  border-radius: 20px 20px 0 0;
  display: flex;
  justify-content: space-between;
  background: #A6443B;
}

.content {
  padding: 20px;
}

.bold {
  font-weight: 700;
}


.uberschrift {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 5px;
}


.buttons {
  display: flex;
  justify-content: center;
}
</style>
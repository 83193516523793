<template>
  <div class="modal">
    <div class="header" :style="{background: calColor}">
      <div>Neue Buchung mit <span class="bold">{{calName}}</span> am <span class="bold">{{formattedStart}}</span></div>
      <div @click="abbrechen" class="pointer"><span class="material-icons icon bold">close</span></div>
    </div>

    <div class="content">
      <div class="selects-container">
        <div style="margin-right: 15px">
          <div class="uberschrift">Behandlung</div>
          <select v-model="appType" class="sel full-width" @change="appTypeChange">
            <option v-for="appType in appointmentTypes" :value="appType.id">{{ appType.name }}</option>
          </select>
        </div>
        <div>
          <div class="uberschrift">Uhrzeit</div>
          <div v-if="loadingTimes" class="loading">
            <Spinner line-fg-color="#2c3e50" line-bg-color="#fff" />
          </div>
          <div v-else>
            <select v-model="selectedTime" class="sel full-width">
              <option v-for="av in newAv">{{ av }}</option>
            </select>
          </div>
        </div>
      </div>
      
      <div class="uberschrift">Anmerkungen</div>
      <textarea cols="40" rows="2" v-model="infos" class="infos"></textarea>

      <div v-if="selectedTime">
        <table class="summary">
          <tr>
            <th>Behandlung</th>
            <td>{{appTypeSelection[0].name}}</td>
          </tr>
          <tr>
            <th>Therapeut*in</th>
            <td>{{calName}}</td>
          </tr>
          <tr>
            <th>Datum</th>
            <td>{{formattedStart}}</td>
          </tr>
           <tr>
            <th>Uhrzeit</th>
            <td>{{selectedTime}} Uhr</td>
          </tr>
        </table>
      </div>
      
      <div class="bottom-button">
        <button 
          class="btn bearbeiten" 
          :class="{ disabled: !selectedTime }" 
          @click="createApp">Buchen
        </button>
      </div>
      
    </div>
  </div>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
import Spinner from "vue-simple-spinner";

export default {
  props: ["selectedAppType"],
  components: {
    Spinner
  },
  data() {
    return {
      calName: this.$route.params.calName,
      calId: this.$route.params.calId,
      start: this.$route.params.start,
      calColor: this.$route.params.calColor,
      formattedStart: moment(this.$route.params.start).format("DD. MM. YYYY"),
      selectedTime: "",
      appType: this.selectedAppType,
      newAv: [],
      loadingTimes: false,
      infos: ""
    };
  },
  methods: {
    appTypeChange() {
      this.fetchAvTimes()
    },
    abbrechen() {
      this.$router.push({ name: "dashboard" })
    },
    get10MinSlots(day, startTime) {
      const start = moment(day + startTime, 'YYYY-MM-DDHH:mm');
      const end = moment(day + '22:00:00', 'YYYY-MM-DDHH:mm');
      const timeSeries = [];

      while (start.isSameOrBefore(end)) {
        timeSeries.push(start.add(10, 'm').format('YYYY-MM-DDTHH:mm:ssZZ'));
      }
      return timeSeries
    },
    getBlocksForWeek(calId, min, max) {
      return this.$store.dispatch("fetchBlocksForCal", { id: calId, min: min, max: max})
    },
    getBookingsForWeek(calId, min, max) {
      return this.$store.dispatch("fetchApsForCal", { id: calId, min: min, max: max})
    },
    async fetchAvTimes() {
      this.loadingTimes = true
      let day = moment(this.start).format('YYYY-MM-DD')

      let appT = this.appointmentTypes.filter(el => {
        return el.id == this.selectedAppType
      })
      appT = appT[0]

      let slots

      // get day slots
      if ( day === moment().format('YYYY-MM-DD')) {
        let start = moment().add(2, 'hours')
        let remainder = 10 - (start.minute() % 10)
        const startTime = moment(start)
          .add(remainder, "minutes")
          .format("HH:mm")
        slots = this.get10MinSlots(day, startTime)
      } else {
        slots = this.get10MinSlots(day, '06:00')
      }

      // get blocks for day
      await this.getBlocksForWeek(this.calId, day, day)
      let blocks = JSON.parse(JSON.stringify(this.$store.state.calBlocks))

      // augment blocks
      blocks.forEach(el => {
        el.start = moment(el.start, 'YYYY-MM-DDTHH:mm:ssZZ').subtract(appT.duration + 10, 'minutes').format('YYYY-MM-DDTHH:mm:ssZZ')
      })

      // get bookings for day
      await this.getBookingsForWeek(this.calId, day, day)
      let bookings = JSON.parse(JSON.stringify(this.$store.state.calAps))

      // augment bookings
      if (bookings.length) {
        bookings.forEach(booking => {
          if (booking.email === this.userProfile.email) {
            // console.log('at hotel', booking)
            booking.start = moment(booking.time, "HH:mm").subtract(appT.duration + 10, 'minutes').format("HH:mm")
            booking.end = moment(booking.endTime, "HH:mm").add(10, 'minutes').format("HH:mm")
          } else if (booking.category === "API Test GiT") {
            // console.log('at GIT', booking)
            booking.start = moment(booking.time, "HH:mm").subtract(appT.duration + 90, 'minutes').format("HH:mm")
            booking.end = moment(booking.endTime, "HH:mm").add(90, 'minutes').format("HH:mm")
          } else {
            // console.log('not at hotel', booking)
            booking.start = moment(booking.time, "HH:mm").subtract(appT.duration + 60, 'minutes').format("HH:mm")
            booking.end = moment(booking.endTime, "HH:mm").add(60, 'minutes').format("HH:mm")
          }
        })
      }

      // subtract bookings from slots, subtract blocks from slots
      let index = slots.length
      while (index--) {
        blocks.forEach(b => {
          if (moment(slots[index], 'YYYY-MM-DDTHH:mm:ssZZ').isBetween(moment(b.start, 'YYYY-MM-DDTHH:mm:ssZZ'), moment(b.end, 'YYYY-MM-DDTHH:mm:ssZZ'))) {
            slots.splice(index, 1); 
          }
        })

        if (bookings.length) {
          bookings.forEach(booking => {
            let momentSlot = moment(slots[index], 'YYYY-MM-DDTHH:mm:ssZZ')
            // let momentSlotPlusDuration = moment(day.slots[index], 'YYYY-MM-DDTHH:mm:ssZZ').add(selectedAppType.duration, 'minutes')
            let momentBookingStart = moment(day + booking.start, 'YYYY-MM-DDHH:mm')
            let momentBookingEnd = moment(day + booking.end, 'YYYY-MM-DDHH:mm')
            // if (momentSlotPlusDuration.isBetween(momentBookingStart, momentBookingEnd) || momentSlot.isBetween(momentBookingStart, momentBookingEnd)) {
            if ( momentSlot.isBetween(momentBookingStart, momentBookingEnd )) {
              console.log(slots[index])
              slots.splice(index, 1)
            }
          })
        }
      }
      const formattedSlots = slots.map(el => {
        return moment(el, 'YYYY-MM-DDTHH:mm:ssZZ').format("HH:mm")
      })
      // console.log(formattedSlots)
      this.newAv = formattedSlots
      this.loadingTimes = false
    },
    // fetchAvTimes() {
    //   this.loadingTimes = true;
    //   this.$store
    //     .dispatch("fetchApsForCal", {
    //       id: this.calId,
    //       min: moment(this.start).format("YYYY-MM-DD"),
    //       max: moment(this.start).format("YYYY-MM-DD")
    //     })
    //     .then(bookings => {
    //       this.$store
    //         .dispatch("fetchAvByCal", {
    //           appType: this.appType,
    //           calId: this.calId,
    //           date: moment(this.start).format("YYYY-MM-DD")
    //         })
    //         .then(av => {
    //           if (bookings.length) {
    //             let avCopy = av;
    //             for (const b of bookings) {
    //               if (b.email != this.userProfile.email || !b.email) {
    //                 let appT = this.appointmentTypes.filter(el => {
    //                   return el.id == this.selectedAppType;
    //                 });
    //                 let start = moment(b.datetime);
    //                 let end =
    //                   moment(b.datetime).format("YYYY-MM-DD") + "T" + b.endTime;

    //                 let startMod = start
    //                   .clone()
    //                   .subtract(60 + appT[0].duration, "minutes");
    //                 let endMod = moment(end).add(60, "minutes");

    //                 avCopy = avCopy.filter(el => {
    //                   return !moment(el.time).isBetween(startMod, endMod);
    //                 });
    //               } else {
    //                 this.newAv = avCopy;
    //                 this.loadingTimes = false;
    //               }
    //             }
    //             this.newAv = avCopy;
    //             this.loadingTimes = false;
    //           } else {
    //             this.newAv = av;
    //             this.loadingTimes = false;
    //           }
    //         })
    //         .catch(error => {
    //           console.log(error);
    //         });
    //     })
    //     .catch(error => {
    //       console.log(error);
    //     });
    // },
    createApp() {
      let app = {
        appointmentTypeID: this.appType,
        datetime:
          moment(this.start).format("YYYY-MM-DD") + "T" + this.selectedTime,
        firstName: this.userProfile.firstName,
        lastName: this.userProfile.lastName,
        email: this.userProfile.email,
        calendarID: this.calId,
        phone: this.userProfile.phone,
        fields: [{ id: 7007676, value: this.infos }, { id: 6863526, value: this.userProfile.adresse + ' ' + this.userProfile.PLZ + ' ' + ' Wien'}]  // REAL
        // fields: [{ id: 7007342, value: this.infos }, { id: 6863526, value: this.userProfile.adresse + ' ' + this.userProfile.PLZ + ' ' + ' Wien'}] // Test
      };
      this.$emit("createAppointment", app);
      this.$router.push({ name: "dashboard" });
    }
  },
  computed: {
    ...mapState(["userProfile", "appointmentTypes"]),
    appTypeSelection() {
      return this.appointmentTypes.filter(el => {
        return this.appType == el.id
      })
    }
  },
  created() {
    this.fetchAvTimes();
  }
};
</script>

<style scoped>
.modal {
  width: 50%;
  background: #f5f5f5;
  z-index: 5;
  position: relative;
  border-radius: 20px;
  margin-bottom: 100px;
}

.header {
  color: #2c3e50;
  font-size: 20px;
  padding: 20px;
  border-radius: 20px 20px 0 0;
  display: flex;
  justify-content: space-between;
}

.content {
  padding: 20px;
}

.bold {
  font-weight: 700;
}

.disabled {
  background: rgb(182, 182, 182);
  color: #f0f0f0;
  pointer-events: none;
}

.selects-container {
  display: flex;
}

.full-width {
  width: 100%;
}

.uberschrift {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 5px;
}

.infos {
  margin-bottom: 10px;
  border-radius: 2px;
  background: transparent;
  font-family: 'Karla';
  padding: 10px;
  border-color:#2c3e50;
  outline: none;
  width: calc( 100% - 20px );
}

.bottom-button {
  text-align: center;
}

.summary {
  text-align: left;
  width: 100%;
  margin: 10px 0 30px 0;
}

.summary th {
  font-weight: normal;
}

.summary td {
  font-weight: bold;
}
</style>
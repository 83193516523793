import Vue from 'vue'
import Vuex from 'vuex'
const fb = require('../fbConfig.js')
import AppointmentService from '../services/AppointmentService'
import AvailabilityService from '../services/AvailabilityService'

Vue.use(Vuex)

fb.auth.onAuthStateChanged(user => {
  if (user) {
    store.commit('setCurrentUser', user)
    store.dispatch('fetchUserProfile').then(profile => {
      store.dispatch('fetchApsForUser', profile.email)
    })
  }
})

export const store = new Vuex.Store({
  state: {
    currentUser: null,
    userProfile: {},
    userAps: [],
    cals: [],
    avByCal: [],
    avByDate: [],
    calAps: [],
    calBlocks: []
  },
  mutations: {
    setCurrentUser(state, val) {
      state.currentUser = val
    },
    setUserProfile(state, val) {
      state.userProfile = val
    },
    setUserAps(state, val) {
      state.userAps = val
    },
    setCals(state, vals) {
      state.cals = vals
    },
    setAvbyCal(state, val) {
      state.avByCal = val
    },
    setAvbyCalIgnore(state, val) {
      state.avByCal = val
    },
    setAvbyDate(state, val) {
      state.avByDate = val
    },
    setCalAps(state, val) {
      state.calAps = val
    },
    setAppTypes(state, val) {
      state.appointmentTypes = val
    },
    setCalBlocks(state, val) {
      state.calBlocks = val
    },
  },
  actions: {
    fetchUserProfile({
      commit,
      state
    }) {
      return fb.usersCollection.doc(state.currentUser.uid).get().then(res => {
        commit('setUserProfile', res.data())
        return res.data()
      }).catch(err => {
        console.log(err)
      })
    },
    clearData({
      commit
    }) {
      commit('setCurrentUser', null)
      commit('setUserProfile', {})
    },
    fetchApsForUser({
      commit
    }, email) {
      return AppointmentService.getApsforUser(email)
        .then(response => {
          commit('setUserAps', response.data)
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    fetchCalendars({
      commit
    }) {
      return AppointmentService.getCals().then(response => {
        commit('setCals', response.data)
        response.data.forEach(el => {
          if (el.email.includes('getintouch.office+o@gmail.com')) {
            el.gender = "female"
          } else if (el.email.includes('getintouch.office+x@gmail.com')) {
            el.gender = "male"
          }
          if (el.email.includes('color')) {
            let mails = el.email.split(',')
            let parts = mails[3].split('@')
            let col = parts[0].trim()
            el.color = col
          }
        })
        return response.data
      }).catch(error => {
        console.log(error)
      })
    },
    fetchAppTypes({
      commit
    }) {
      return AppointmentService.getAppTypes().then(response => {
        commit('setAppTypes', response.data)
        return response.data
      }).catch(error => {
        console.log(error)
      })
    },
    fetchAvByCal({
      commit
    }, {
      appType,
      calId,
      date
    }) {
      return AvailabilityService.getAvByCal(appType, calId, date).then(response => {
          commit('setAvbyCal', response.data)
          return response.data
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    fetchAvByCalIgnore({
      commit
    }, {
      appType,
      calId,
      date,
      appointmentId
    }) {
      return AvailabilityService.getAvByCalIgnore(appType, calId, date, appointmentId).then(response => {
          commit('setAvbyCalIgnore', response.data)
          return response.data
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    fetchAvByDate({
      commit
    }, {
      appType,
      date
    }) {
      return AvailabilityService.getAvByDate(appType, date).then(response => {
          commit('setAvbyDate', response.data)
          return response.data
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    fetchApsForCal({
      commit
    }, {
      id,
      min,
      max
    }) {
      return AppointmentService.getApsforCal(id, min, max)
        .then(response => {
          commit('setCalAps', response.data)
          return response.data
        })
        .catch(error => {
          console.log(error.response)
        })
    },
    fetchBlocksForCal({
      commit
    }, {
      id,
      min,
      max
    }) {
      return AvailabilityService.getBlocks(id, min, max)
        .then(response => {
          commit('setCalBlocks', response.data)
          return response.data
        })
        .catch(error => {
          console.log(error.response)
        })
    },
  }
})